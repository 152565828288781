import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalConstants } from 'functions/src/shared/global-constants';
import { debounceTime } from 'rxjs/operators';
import { BackurlService } from 'src/common/services/backurl/backurl.service';
import { LogsService } from 'src/common/services/logs/logs.service';
import { SettingService } from 'src/common/services/setting/setting.service';

@Component({
  selector: 'app-all-logs',
  templateUrl: './all-logs.component.html',
  styleUrls: ['./all-logs.component.scss']
})
export class AllLogsComponent implements OnInit {

  public GLOBAL = new GlobalConstants();
  public tableCols = [
    'icon',
    'date',
    'data',
    'user'
  ];
  public module: string;
  public $key: string;

  public sortLocalForm: FormGroup = this.fb.group({
    realtor: [null],
  });

  constructor(
    public logService: LogsService,
    public route: ActivatedRoute,
    protected router: Router,
    public backURL: BackurlService,
    protected fb: FormBuilder,
    public setting: SettingService,) { }

  ngOnInit(): void {

    this.sortLocalForm.valueChanges.pipe(debounceTime(500)).subscribe(filters => {
      this.logService.init('contacts', null, null, null, 'view', filters.realtor);
    });

  }

  goBack() {
    this.router.navigate(['/settings']);
  }
}