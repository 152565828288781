<mat-toolbar>
    <div>
        <button mat-icon-button (click)="goBack()">
            <mat-icon>keyboard_arrow_left</mat-icon>
        </button>
        <div class="title">
            <mat-icon>{{GLOBAL.ICONS.SETTING}}</mat-icon>
            <ng-container>
                SETTINGS
            </ng-container>
        </div>
    </div>
    <profile></profile>

</mat-toolbar>
<div class="toolbartop"></div>
<mat-action-list>
    <a mat-list-item routerLink='/settings/users'>
        <mat-icon matListIcon>group</mat-icon>Users
    </a>
    <a mat-list-item routerLink='/settings/agencies'>
        <mat-icon matListIcon>holiday_village</mat-icon>Agencies
    </a>
    <a mat-list-item routerLink='/settings/logs'>
        <mat-icon matListIcon>{{GLOBAL.ICONS.LOGS}}</mat-icon>Logs
    </a>
    <ng-container *ngFor="let paramSetting of setting.settings | toarray">
        <a *ngIf="paramSetting.editable" mat-list-item routerLink='/settings/list'
            [queryParams]="{key: paramSetting.$key}">
            <mat-icon matListIcon>list</mat-icon>{{paramSetting.title}}
        </a>
    </ng-container>
    <!-- <button mat-list-item (click)="admin.refreshAlias('units')">
        <mat-icon matListIcon>refresh</mat-icon>Spread Units Alias + create sectors
    </button>
    <button mat-list-item (click)="admin.refreshAlias('residences')">
        <mat-icon matListIcon>refresh</mat-icon>Spread Residences Alias + create sectors
    </button> -->
</mat-action-list>