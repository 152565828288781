<mat-toolbar>

    <div>
        <a mat-icon-button routerLink='/settings'>
            <mat-icon>keyboard_arrow_left</mat-icon>
        </a>
        <span>{{usersDiplayed.length}} Users</span>
    </div>
    <profile></profile>

</mat-toolbar>
<div class="toolbartop"></div>
<div class="main-container-flex split s20">
    <div class="main">
        <table mat-table [dataSource]="usersDiplayed" class="mat-elevation-z8">

            <ng-container matColumnDef="photo">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                    <img mat-card-avatar [src]="element.values.extPhoto" />
                </td>
            </ng-container>

            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef> Name </th>
                <td mat-cell *matCellDef="let element"> {{element.values.fullname}} </td>
            </ng-container>

            <ng-container matColumnDef="agency">
                <th mat-header-cell *matHeaderCellDef> Agency </th>
                <td mat-cell *matCellDef="let element">
                    <ng-container *ngFor="let agency of element.values.agencies; let i = index"><br *ngIf="i" />{{agency
                        | param:'agencies'}}</ng-container>
                </td>
            </ng-container>

            <ng-container matColumnDef="created">
                <th mat-header-cell *matHeaderCellDef> Created </th>
                <td mat-cell *matCellDef="let element"> {{element.values.created | date:'dd MMM yy'}} </td>
            </ng-container>

            <ng-container matColumnDef="role">
                <th mat-header-cell *matHeaderCellDef> Role </th>
                <td mat-cell *matCellDef="let element">
                    {{element.values.role | param:'userRoles'}}
                </td>
            </ng-container>

            <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef> Email </th>
                <td mat-cell *matCellDef="let element"> {{element.values.email}} </td>
            </ng-container>

            <ng-container matColumnDef="tel">
                <th mat-header-cell *matHeaderCellDef> Tel </th>
                <td mat-cell *matCellDef="let element"> {{element.values.tel}} </td>
            </ng-container>

            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef> Status </th>
                <td mat-cell *matCellDef="let element">
                    <mat-icon [ngClass]="{active:element.values.status == 'active'}">
                        {{element.values.status | icon:'userStatus'}}
                    </mat-icon>
                </td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                    <button *ngIf="element.values.status == 'pending'" mat-button color="basic"
                        (click)="removeInvitation(element, $event)">
                        <mat-icon matListIcon>delete</mat-icon>
                    </button>
                </td>
            </ng-container>


            <tr mat-header-row
                *matHeaderRowDef="['photo', 'name', 'agency', 'created', 'role', 'email', 'tel', 'status', 'actions']">
            </tr>
            <tr mat-row
                *matRowDef="let row; let element; columns: ['photo', 'name', 'agency', 'created', 'role', 'email', 'tel', 'status', 'actions'];"
                (click)="editUser(element)" [ngClass]="{cursor: element.values.status != 'pending'}"></tr>
        </table>
    </div>
    <div class="side">
        <form [formGroup]="sortLocalForm" novalidate>

            <mat-form-field appearance="fill">
                <mat-label>Agency</mat-label>
                <mat-select formControlName="agency">
                    <mat-option value="">
                        All Agencies
                    </mat-option>
                    <mat-option *ngFor="let param of 'userAgencies' | params" [value]="param.id">
                        {{param.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-slide-toggle formControlName="active">Active
                </mat-slide-toggle>
                <textarea matInput hidden></textarea>
            </mat-form-field>
        </form>

    </div>
</div>


<button mat-fab class="bottom-right pfixed" (click)="addUser()">
    <mat-icon matListIcon>person_add</mat-icon>
</button>