import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalConstants } from 'functions/src/shared/global-constants';
import { debounceTime } from 'rxjs/internal/operators/debounceTime';
import { pairwise, startWith } from 'rxjs/operators';
import { AuthService } from 'src/common/services/auth/auth.service';
import { BackurlService } from 'src/common/services/backurl/backurl.service';
import { SettingService } from 'src/common/services/setting/setting.service';
import { StatService } from 'src/services/stat/stat.service';

@Component({
  selector: 'app-stats',
  templateUrl: './stats.component.html',
  styleUrls: ['./stats.component.scss']
})
export class StatsComponent implements OnInit {

  public GLOBAL = new GlobalConstants();

  public statReports: any = {};
  public chart = false;

  public year: number = null;
  public month: number = null;
  public uniqueStat: string = null;

  public sortLocalForm: FormGroup = this.fb.group({
    agency: [this.auth.isRole('admin') ? null : this.setting.getAgency(this.statService.auth.uid)],
    realtor: [this.isRealtor() ? this.statService.auth.uid : null],
  });

  public sortOptionsForm: FormGroup = this.fb.group({
    statReport: ['sales'],
    type: [null],
    uniqueStatPerc: [false],
  });


  public customColors: any[] = [
    { name: 'Cold', value: '#B1E9E1' }, // Red
    { name: 'Warm', value: '#FF8C00' }, // Green
    { name: 'Hot', value: '#FF4500' }, // Blue
    { name: 'VEFA', value: '#4CAF50' }, // Blue
    // Add more mappings as needed
  ];

  // public months = [{ name: 'January', value: '01' },
  // { name: 'February', value: '02' },
  // { name: 'March', value: '03' },
  // { name: 'April', value: '04' },
  // { name: 'May', value: '05' },
  // { name: 'June', value: '06' },
  // { name: 'July', value: '07' },
  // { name: 'August', value: '08' },
  // { name: 'September', value: '09' },
  // { name: 'October', value: '10' },
  // { name: 'January', value: '11' },
  // { name: 'January', value: '12' },
  public months = [
    'none',
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];


  // view: any[] = [700, 400];

  // options
  // gradient: boolean = true;
  showLegend: boolean = true;
  showLabels: boolean = true;
  isDoughnut: boolean = false;
  legendPosition: string = 'below';


  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient: boolean = false;
  showXAxisLabel: boolean = true;
  xAxisLabel: string = 'Year';
  showYAxisLabel: boolean = true;
  yAxisLabel: string = 'Leads';
  animations: boolean = true;


  constructor(
    public auth: AuthService,
    public statService: StatService,
    public setting: SettingService,
    public route: ActivatedRoute,
    protected router: Router,
    public backURL: BackurlService,
    protected fb: FormBuilder,) {
  }

  ngOnInit(): void {

    this.statService.initList(this.sortOptionsForm.value.statReport, this.sortLocalForm.value.agency, this.sortLocalForm.value.realtor, this.year, this.month, this.sortOptionsForm.value.type);
    const initialLocalValues = this.sortLocalForm.value;

    this.sortLocalForm.valueChanges.pipe(startWith(initialLocalValues), debounceTime(500), pairwise()).subscribe(([prevValues, currentValues]) => {
      setTimeout(() => {
        if (prevValues.agency !== currentValues.agency) {
          this.sortLocalForm.get('realtor')?.setValue(null);
        }
        if (currentValues.agency === 'byAgency' || currentValues.realtor === 'byRealtor') {
          this.sortOptionsForm.get('uniqueStatPerc')?.setValue(false);
          this.uniqueStat = null;
        }
        this._listRefresh();
      }, 10);
    });

    const initialOPtionValues = this.sortOptionsForm.value;

    this.sortOptionsForm.valueChanges.pipe(startWith(initialOPtionValues), debounceTime(500), pairwise()).subscribe(([prevValues, currentValues]) => {
      setTimeout(() => {
        if (prevValues.statReport !== currentValues.statReport) {
          this.sortLocalForm.get('type')?.setValue(null);
          this.sortOptionsForm.get('uniqueStatPerc')?.setValue(false);
          this.uniqueStat = null;
        }
        // this.series = null;
        this.statService.refreshChartOptions(this.sortOptionsForm.value.statReport, this.sortOptionsForm.value.type, this.year, this.month, this.uniqueStat, currentValues.uniqueStatPerc, this.sortLocalForm.value.agency === 'byAgency' ? 'agency' : (this.sortLocalForm.value.realtor === 'byRealtor' ? 'realtor' : null));
      }, 10);
    });
  }

  private _listRefresh() {
    // this.series = null;
    this.statService.initList(this.sortOptionsForm.value.statReport, this.sortLocalForm.value.agency, this.sortLocalForm.value.realtor, this.year, this.month, this.sortOptionsForm.value.type, this.uniqueStat, this.sortOptionsForm.value.uniqueStatPerc);
  }

  public viewchart() {
    this.chart = true;
  }

  goBack() {
    this.router.navigate([this.backURL.back()]);
  }

  backToList() {
    if (!this.chart) {
      if (this.year) {
        if (this.month) {
          this.month = null;
        } else {
          this.year = null;
        }
        this._listRefresh();
      } else {
        this.goBack();
      }
    } else {
      this.chart = false;
    }
  }

  onSelectBar(data): void {
    // console.log('Item clicked 1', JSON.stringify(data));

    // Manage click Legend
    if (typeof data === 'string') {
      if (this.sortLocalForm.value.agency !== 'byAgency' && this.sortLocalForm.value.realtor !== 'byRealtor') {
        if (data === 'All') {
          this.sortOptionsForm.get('uniqueStatPerc')?.setValue(false);
          this.uniqueStat = null;
        } else {
          this.uniqueStat = data;
        }
        this.statService.refreshChartOptions(this.sortOptionsForm.value.statReport, this.sortOptionsForm.value.type, this.year, this.month, this.uniqueStat, this.sortOptionsForm.value.uniqueStatPerc, this.sortLocalForm.value.agency === 'byAgency' ? 'agency' : (this.sortLocalForm.value.realtor === 'byRealtor' ? 'realtor' : null));
      }
      return;
    }

    // Manage click Colonns
    if (data.series) {
      if (!(this.year && this.month)) {
        if (parseInt(data.series) > 12) {
          this.year = parseInt(data.series);
          this.month = null;
        } else {
          this.month = parseInt(data.series);
        }
        this._listRefresh();
      }
    }

  }

  isRealtor(): boolean {
    return this.auth.isRole('realtor');
  }
}


