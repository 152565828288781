import { max } from 'moment';
import { MainItem, Field, Email, Tel, Alert, AgencyFeeFields } from '../../common/services/main/main.model';
import { SettingService } from '../../common/services/setting/setting.service';


export interface ActionAgencyFee {
    type?: string,
    comPerc?: number,
    comFix?: number,
    comMin?: number,
}

export class ActionAgencyFeeFields {
    type = new Field({
        label: 'Agency Fee', input: 'select', inputOptions: { param: 'agencyFeeTypes' },
        validations: { required: true },
        default: 'std',
    });
    comPerc = new Field({
        input: 'number', label: 'Agency Fee (%)',
        validations: { required: true },
        show: (data: any, subData: any) => subData && subData.type === 'otherPerc',
    });
    comFix = new Field({
        input: 'number', label: 'Fix Amount + VAT',
        validations: { required: true },
        show: (data: any, subData: any) => subData && subData.type === 'fix',
    });
    comMin = new Field({
        input: 'number', label: 'Min Amount + VAT',
        show: (data: any, subData: any) => subData && subData.type === 'std',
    });
    shared = new Field({
        input: 'number', label: 'Share Fee (%)', validations: { max: 100 },
    });
    broker = new Field({
        input: 'number', label: 'Broker Fee (%)', validations: { max: 100 }
    });
}

export class Action extends MainItem {

    values: {
        $key: string,
        ref: number,
        created: firebase.firestore.FieldValue,
        createdBy: string,
        updated: firebase.firestore.FieldValue,
        updatedBy: string,
        alerts: Alert[],

        synaps: any,

        type: string, // Discovery, Visit, ...
        medium: string, // Call, WhatsApp, Email, Meet...
        date: Date,
        doneDate: Date,
        done: boolean,
        realtor: string,
        agency: string,
        note: string,
        data: any,
    }


    constructor(
        values: any = null,
        public setting?: SettingService,
        fields: { [key: string]: Field } = {},
        sections: { [key: string]: any } = {},
    ) {
        super(
            'actions',
            Object.assign({
                type: new Field({ label: 'Type', }),
                medium: new Field({ label: 'Medium', }),
                date: new Field({ input: 'date', label: 'Date', }),
                done: new Field({ input: 'boolean', label: 'Done', }),
                doneDate: new Field({ input: 'date', label: 'Done Date', }),
                realtor: new Field({ label: 'Agent' }),
                agency: new Field({
                    label: 'Agency', input: 'select', inputOptions: { param: 'agencies' },
                    validations: { required: true },
                    // input: 'auto',
                    // autoFn: {
                    //     toFB: (values, setting) => { return this._getAgency(setting, values.realtor) }
                    // }
                }),
                note: new Field({ label: 'Note', validations: { required: true } }),
                data: new Field({
                    label: 'Action Data',
                    input: 'objectSubType',
                    subType:
                    {
                        newRequest: {
                            origin: new Field({
                                label: 'Origin', input: 'select', inputOptions: { param: 'projectOrigins' },
                                validations: { required: true },
                            }),
                            originMedium: new Field({
                                label: 'Origin Medium', input: 'select', inputOptions: { param: 'mediums' },
                                validations: { required: true },
                            }),
                            ref: new Field({
                                label: 'Mandate Reference (Nb)', input: 'number',
                            }),
                            projectType: new Field({
                                label: 'Project Type', input: 'select', inputOptions: { param: 'projectTypes' },
                                show: (data) => { return !(data.data && data.data.ref) },
                                validations: { required: true },
                            }),
                            title: new Field({
                                label: 'Title', input: 'select', inputOptions: { param: 'titles' },
                            }),
                            firstname: new Field({
                                label: 'Firstname',
                            }),
                            lastname: new Field({
                                label: 'Lastname',
                            }),
                            tel: new Field({
                                label: 'Tel',
                            }),
                            email: new Field({
                                label: 'Email', validations: { email: true }
                            }),
                            lang: new Field({
                                label: 'Language', input: 'select', inputOptions: { param: 'langs' },
                            }),
                            country: new Field({
                                label: 'Country', input: 'select', inputOptions: { param: 'countries' },
                            }),
                            msg: new Field({
                                label: 'Message', input: 'textarea',
                            }),
                            referral: new Field({
                                label: 'Referral', input: 'select', inputOptions: { param: 'users' },
                            }),
                        },
                        discovery: {
                            dateNeed: new Field({
                                label: 'Moving Date', input: 'date',
                                validations: { required: true },
                            }),
                            temperature: new Field({
                                label: 'Temperature', input: 'select', inputOptions: { param: 'temperatures' },
                                validations: { required: true },
                            }),
                            foreigner: new Field({
                                input: 'boolean', label: 'Foreigner',
                                show: (data) => {
                                    return data &&
                                        data.synaps &&
                                        data.synaps.projectsActionsObj &&
                                        data.synaps.projectsActionsObj.type === 'sale'
                                },
                            }),
                            residentPermit: new Field({
                                input: 'boolean',
                                label: 'Need Resident Permit',
                                show: (data) => {
                                    return data &&
                                        data.synaps &&
                                        data.synaps.projectsActionsObj &&
                                        data.synaps.projectsActionsObj.type === 'sale' &&
                                        data.data &&
                                        data.data.foreigner;
                                },
                            }),
                            sectors: new Field({
                                label: 'Sectors',
                                input: 'select', inputOptions: { param: 'sectors', multi: true },
                                validations: { required: true },
                            }),
                            unitTypes: new Field({
                                label: 'Property Types',
                                input: 'select', inputOptions: { param: 'unitTypes', multi: true, icon: true },
                                validations: { required: true },
                            }),
                            conditions: new Field({
                                label: 'Property Conditions',
                                input: 'select', inputOptions: { param: 'unitConditions', multi: true },
                                show: (data) => {
                                    return data &&
                                        data.synaps &&
                                        data.synaps.projectsActionsObj &&
                                        data.synaps.projectsActionsObj.type === 'sale'
                                },
                            }),
                            minBedrooms: new Field({
                                input: 'number', label: 'Minimum Bedrooms',
                                show: (data) => {
                                    return data && data.data && data.data.unitTypes && (data.data.unitTypes.includes('apartment') || data.data.unitTypes.includes('house'));
                                },
                                validations: { required: true },
                            }),
                            maxBudget: new Field({
                                input: 'number', label: 'Maximum Budget',
                                validations: { required: true },
                            }),
                            cyBudget: new Field({
                                label: 'Currency',
                                input: 'select', inputOptions: { param: 'cies' },
                                validations: { required: true },
                                default: 'mur',
                            }),
                        },
                        // offer: {
                        //     price: new Field({
                        //         input: 'number', label: 'Offer Amount',
                        //         validations: { required: true },
                        //     }),
                        //     cy: new Field({
                        //         label: 'Currency',
                        //         input: 'select', inputOptions: { param: 'cies' },
                        //         validations: { required: true },
                        //         default: 'mur',
                        //     }),
                        //     accepted: new Field({
                        //         input: 'boolean', label: 'Accepted',
                        //         show: () => { return false },
                        //     }),
                        //     note: new Field({ label: 'Note' }),
                        // },
                        // signAgree: {
                        //     price: new Field({
                        //         input: 'number', label: 'Transaction Amount',
                        //         validations: { required: true },
                        //     }),
                        //     cy: new Field({
                        //         label: 'Currency',
                        //         input: 'select', inputOptions: { param: 'cies' },
                        //         validations: { required: true },
                        //         default: 'mur',
                        //     }),
                        //     dateSignDeed: new Field({
                        //         input: 'date', label: 'Date Signature Deed of Sale',
                        //         validations: { required: true },
                        //     }),
                        //     accepted: new Field({
                        //         input: 'boolean', label: 'Accepted',
                        //         show: () => { return false },
                        //     }),
                        //     note: new Field({ label: 'Note' }),
                        // },
                        // signDeed: {
                        //     price: new Field({
                        //         input: 'number', label: 'Transaction Amount',
                        //         validations: { required: true },
                        //     }),
                        //     cy: new Field({
                        //         label: 'Currency',
                        //         input: 'select', inputOptions: { param: 'cies' },
                        //         validations: { required: true },
                        //         default: 'mur',
                        //     }),
                        //     accepted: new Field({
                        //         input: 'boolean', label: 'Accepted',
                        //         show: () => { return false },
                        //     }),
                        //     note: new Field({ label: 'Note' }),
                        // },
                        // signLease: {
                        //     price: new Field({
                        //         input: 'number', label: 'Lease Amount',
                        //         validations: { required: true },
                        //     }),
                        //     cy: new Field({
                        //         label: 'Currency',
                        //         input: 'select', inputOptions: { param: 'cies' },
                        //         validations: { required: true },
                        //         default: 'mur',
                        //     }),
                        //     accepted: new Field({
                        //         input: 'boolean', label: 'Accepted',
                        //         show: () => { return false },
                        //     }),
                        //     note: new Field({ label: 'Note' }),
                        // },
                        price: {
                            price: new Field({
                                input: 'number', label: 'New Price',
                                validations: { required: true },
                            }),
                            priceNeg: new Field({
                                input: 'number', label: 'New Negociable Price',
                            }),
                            cy: new Field({
                                label: 'Currency',
                                input: 'select', inputOptions: { param: 'cies' },
                                validations: { required: true },
                                default: 'mur',
                            }),
                            note: new Field({ label: 'Note' }),
                        },
                        payment: {
                            paymentType: new Field({
                                label: 'Type',
                                input: 'select', inputOptions: { param: 'paymentTypes' },
                                validations: { required: true },
                            }),
                            ref: new Field({
                                label: 'Ref',
                                validations: { required: true },
                            }),
                            amount: new Field({
                                input: 'number', label: 'Amount (Rs)',
                                validations: { required: true },
                            }),
                            cy: new Field({
                                label: 'Currency',
                                input: 'select', inputOptions: { param: 'cies' },
                                validations: { required: true },
                                default: 'mur',
                                show: () => false,
                            }),
                        },
                        handover: {
                            from: new Field(),
                            to: new Field(),
                        },
                        temperature: {
                            from: new Field(),
                            to: new Field(),
                        },
                        visitReport: {
                            com: new Field({
                                input: 'boolean', label: 'Reported?', default: false,
                                show: () => { return false },
                            }),
                            medium: new Field({
                                label: 'How?',
                                input: 'select', inputOptions: { param: 'mediums' },
                                validations: { required: true },
                            }),
                            send: new Field({
                                input: 'boolean', label: 'Send the Email?', default: false,
                                show: (value) => { return value.data && value.data.medium === 'email' },
                            }),
                            sent: new Field({
                                input: 'boolean', label: 'Sent', default: false,
                                show: () => { return false },
                            }),
                            note: new Field({
                                label: 'Report', validations: {
                                    required:
                                        (data: any) =>
                                            data && data.data && data.data.medium === 'email' && data.data.send
                                }
                            }),
                        },
                        newMandate: {
                            origin: new Field({
                                label: 'Origin', input: 'select', inputOptions: { param: 'mandateOrigins' },
                                validations: { required: true },
                            }),
                            msg: new Field({
                                label: 'Message', input: 'textarea',
                            }),
                            referral: new Field({
                                label: 'Referral', input: 'select', inputOptions: { param: 'users' },
                            }),
                        },
                        r1: {
                            estimation: new Field({
                                label: 'Estimation',
                                input: 'boolean',
                                default: true
                            }),
                            temperature: new Field({
                                label: 'Temperature', input: 'select', inputOptions: { param: 'temperatures' },
                                validations: { required: true }
                            }),
                            note: new Field({ input: 'textarea', label: 'Note' }),
                        },
                        r2: {
                            temperature: new Field({
                                label: 'Temperature', input: 'select', inputOptions: { param: 'temperatures' },
                                validations: { required: true }
                            }),
                            price: new Field({
                                input: 'number', label: 'Estimate Price',
                                validations: { required: true },
                            }),
                            // priceFurniture: new Field({
                            //     input: 'number', label: 'Including Furniture Price',
                            //     show: (data) => {
                            //         return data &&
                            //             data.synaps &&
                            //             data.synaps.mandatesActionsObj &&
                            //             data.synaps.mandatesActionsObj.type === 'sale'
                            //     },
                            // }),
                            cy: new Field({
                                label: 'Currency',
                                input: 'select', inputOptions: { param: 'cies' },
                                validations: { required: true },
                                default: 'mur',
                            }),
                            note: new Field({ input: 'textarea', label: 'Note' }),
                        },
                        failed: {
                            failedReason: new Field({
                                label: 'Reason', input: 'select', inputOptions: { param: 'projectFailedReasons' },
                                validations: { required: true },
                            }),
                            note: new Field({
                                label: 'Note', input: 'textarea',
                            }),
                        },
                        advertise: {
                            ad: new Field({
                                label: 'Advertise',
                                input: 'boolean',
                                default: false,
                            }),
                            priceVisible: new Field({
                                label: 'Show Price',
                                input: 'boolean',
                                default: true,
                            }),
                            web: new Field({
                                label: 'Nestenn Websites',
                                input: 'boolean',
                                default: true,
                                show: (data: any) => !!data.data.ad,
                            }),
                            lp: new Field({
                                label: 'L\'Express Property',
                                input: 'boolean',
                                default: true,
                                show: (data: any) => !!data.data.ad,
                            }),
                            pc: new Field({
                                label: 'Property Cloud',
                                input: 'boolean',
                                default: true,
                                show: (data: any) => !!data.data.ad,
                            }),
                            je: new Field({
                                label: 'JamesEdition',
                                input: 'boolean',
                                default: true,
                                show: (data: any) => !!data.data.ad,
                            }),
                            pm: new Field({
                                label: 'PropertyMap',
                                input: 'boolean',
                                default: true,
                                show: (data: any) => !!data.data.ad,
                            }),
                            ta: new Field({
                                label: 'The Agency',
                                input: 'boolean',
                                default: false,
                                show: (data: any) => !!data.data.ad,
                            }),
                            apimo: new Field({
                                label: 'Polo Properties',
                                input: 'boolean',
                                default: false,
                                show: (data: any) => !!data.data.ad,
                            }),
                            mer: new Field({
                                label: 'Mer & Demeures',
                                input: 'boolean',
                                default: false,
                                show: (data: any) => !!data.data.ad,
                            }),
                            ps: new Field({
                                label: 'Properstar',
                                input: 'boolean',
                                default: false,
                                show: (data: any) => !!data.data.ad,
                            }),
                        },
                    },
                }),
            }, fields),
            // Forms
            Object.assign({
                main: {
                    title: null,
                    fields: ['data']
                },
                agencymain: {
                    title: null,
                    fields: ['agency', 'data']
                },
                notemain: {
                    title: null,
                    fields: ['note', 'data']
                },
                mainnote: {
                    title: null,
                    fields: ['data', 'note']
                },
                note: {
                    title: null,
                    fields: ['note']
                },
            }, sections),
            // Tables
            {
                main: {
                    title: 'Action List',
                    columns: [
                        { field: 'icon', type: 'icon', label: '' },
                        { field: 'date', type: 'date', label: 'Date' },
                        { field: 'fullnames', type: 'string', label: 'Name', options: { multi: true } },
                        { field: 'details', type: 'string', label: 'Details' },
                    ],
                },
                mainRealtor: {
                    title: 'Action List',
                    columns: [
                        { field: 'icon', type: 'icon', label: '' },
                        { field: 'date', type: 'date', label: 'Date' },
                        { field: 'fullnames', type: 'string', label: 'Name', options: { multi: true } },
                        { field: 'details', type: 'string', label: 'Details' },
                        { field: 'realtor', type: 'user', label: 'Agent' },
                    ],
                },
                newRequest: {
                    title: 'New Request List',
                    columns: [
                        { field: 'icon', type: 'icon', label: '' },
                        { field: 'date', type: 'ago', label: 'Time Ago' },
                        { field: 'origin', type: 'string', label: 'Origin' },
                        { field: 'fullnames', type: 'string', label: 'Name', options: { multi: true } },
                        { field: 'budget', type: 'string', label: 'Price' },
                        { field: 'referral', type: 'user', label: 'Referral' },
                    ],
                },
                newRequestRealtor: {
                    title: 'New Request List',
                    columns: [
                        { field: 'icon', type: 'icon', label: '' },
                        { field: 'date', type: 'ago', label: 'Time Ago' },
                        { field: 'origin', type: 'string', label: 'Origin' },
                        { field: 'fullnames', type: 'string', label: 'Name', options: { multi: true } },
                        { field: 'budget', type: 'string', label: 'Price' },
                        { field: 'referral', type: 'user', label: 'Referral' },
                        { field: 'realtor', type: 'user', label: 'Agent' },
                    ],
                },
            },
            // Config File
            {
                path: 'actions/', filename: (data) => {
                    let filename = 'action-';
                    filename += 'A' + data.ref;
                    return filename
                }
            },
            setting,
        );
        if (values) {
            // this.fromFB(values);
            Object.assign(this.values, values);
        }

    }


}