<mat-sidenav-container>
  <mat-sidenav *ngIf="auth.loggedIn" [ngClass]="{large: menuLarge}" #drawer mode="side" opened disableClose
    role="navigation">
    <mat-toolbar>
      <mat-toolbar-row class="mainlogo">
        <!-- <img src="./assets/images/logo.png"> -->
      </mat-toolbar-row>
    </mat-toolbar>
    <mat-nav-list>
      <a mat-list-item routerLink='/' [disabled]="select.active">
        <mat-icon matListIcon matTooltip="Dashboard" class="dash">{{GLOBAL.ICONS.DASHBOARD}}</mat-icon>
      </a>
      <a mat-list-item routerLink='/mytasks' [disabled]="select.active">
        <mat-icon [matBadgeHidden]="!myActions.actionTypes?.newRequest" [matBadge]="myActions.actionTypes?.newRequest"
          matBadgePosition="below after" matBadgeColor="accent" matListIcon matTooltip="Tasks"
          class="actions">{{GLOBAL.ICONS.ACTIONS}}</mat-icon>
      </a>
      <!-- ASSIGN LEADS -->
      <a *ngIf="newRequests.isOneOfRoles(['assistant','director', 'admin'])" mat-list-item
        (click)="goList('/assign-leads')" [disabled]="select.active" matTooltip="New Requests">
        <mat-icon [matBadgeHidden]="!newRequests.notAssigned" [matBadge]="newRequests.notAssigned"
          matBadgePosition="below after" matBadgeColor="primary" [color]="newRequests.notAssigned ? 'warn' : ''"
          matListIcon>{{GLOBAL.ICONS.NEWREQUEST}}
        </mat-icon>
      </a>
      <a mat-list-item (click)="goList('/projects')" [disabled]="select.active" matTooltip="Projects" class="sepup">
        <mat-icon matListIcon class="projects">{{GLOBAL.ICONS.PROJECT}}</mat-icon><span>Projects</span>
      </a>
      <a mat-list-item (click)="goList('/mandates')" [disabled]="select.active" matTooltip="Mandates" class="sepdown">
        <mat-icon matListIcon class="mandates">{{GLOBAL.ICONS.MANDATE}}</mat-icon><span>Mandates</span>
      </a>
      <a mat-list-item (click)="goList('/units')" [disabled]="select.active" matTooltip="Properties">
        <mat-icon matListIcon class="units">{{GLOBAL.ICONS.UNIT}}</mat-icon><span>Properties</span>
      </a>
      <a mat-list-item (click)="goList('/residences')" [disabled]="select.active" matTooltip="Residences">
        <mat-icon matListIcon class="residences">{{GLOBAL.ICONS.RESIDENCE}}</mat-icon><span>Residences</span>
      </a>
      <a mat-list-item (click)="goList('/contacts')" [disabled]="select.active" matTooltip="Contacts" class="sepdown">
        <mat-icon matListIcon class="contacts">{{GLOBAL.ICONS.CONTACTS}}</mat-icon><span>Contacts</span>
      </a>
      <a *ngIf="newRequests.isOneOfRoles(['marketing', 'admin', 'director'])" mat-list-item (click)="goList('/marketing' )"
        [disabled]="select.active" matTooltip="Marketing">
        <mat-icon class="marketing" matListIcon>{{GLOBAL.ICONS.BLOG}}
        </mat-icon><span>Marketing</span>
      </a>
      <a *ngIf="newRequests.isOneOfRoles(['marketing', 'director', 'admin', 'realtor','assistant'])" mat-list-item routerLink='/stats'
        [disabled]="select.active">
        <mat-icon matListIcon matTooltip="Stats">{{GLOBAL.ICONS.STAT}}</mat-icon>
      </a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content [ngClass]="{large: menuLarge}">
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>