import { Component} from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { GlobalConstants } from 'functions/src/shared/global-constants';
import { MainsComponent } from 'src/common/pages/mains/mains.component';
import { BackurlService } from 'src/common/services/backurl/backurl.service';
import { SelectService } from 'src/common/services/select/select.service';
import { SettingService } from 'src/common/services/setting/setting.service';
import { Action } from 'src/services/action/action.model';
import { ActionMarketingService } from 'src/services/action/action.service';

@Component({
  selector: 'app-marketing',
  templateUrl: './marketing.component.html',
  styleUrls: ['./marketing.component.scss']
})
export class MarketingComponent extends MainsComponent {

  // public newRequests: ActionService = null;
  public actionItem = new Action(null, this.settings);
  public GLOBAL = new GlobalConstants();

  public preview: any = null;

  constructor(
    protected afs: AngularFirestore,
    public db: ActionMarketingService,
    public settings: SettingService,
    public backURL: BackurlService,
    protected router: Router,
    public setting: SettingService,
    public select: SelectService,
    protected fb: FormBuilder,
  ) {
    super(setting, db, select, router, fb, backURL)

  }



  goItem(item: any) {
    this.backURL.addURL('/tasks');
    if (item.project && item.project.$key) {
      this.router.navigate([`/projects/${item.project.$key}`]);
    } else if (item.mandate && item.mandate.$key) {
      this.router.navigate([`/mandates/${item.mandate.$key}`]);
    }
  }
}
