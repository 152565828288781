import { Component } from '@angular/core';
import { AuthService } from '../../services/auth/auth.service';
import { Profile } from '../../services/auth/auth.model';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SettingService } from 'src/common/services/setting/setting.service';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent {

  public usersActivePending: Profile[] = [];
  public usersDiplayed: Profile[] = [];

  public sortLocalForm: FormGroup = this.fb.group({
    agency: [null],
    active: [true],
  });


  constructor(
    public auth: AuthService,
    protected router: Router,
    protected fb: FormBuilder,
    public setting: SettingService,
  ) {
  }

  ngOnInit(): void {

    this.auth.usersActivePending$.subscribe(profiles => {
      this.usersActivePending = profiles;
      this._listRefresh();
    });

    this.sortLocalForm.valueChanges.pipe(debounceTime(500)).subscribe(() => {
      setTimeout(() => {
        this._listRefresh();
      }, 10);
    });
  }

  _listRefresh() {
    const { agency, active } = this.sortLocalForm.value;

    this.usersDiplayed = this.usersActivePending.filter(user => {
      const matchesAgency = agency ? user.values.agency === agency : true;
      const matchesActive = active ? user.values.status === 'active' : true;
      return matchesAgency && matchesActive;
    });
  }


  addUser() {
    this.router.navigate([`/settings/users/0`]);
  }

  editUser(user: Profile) {
    if (user.values.status != 'pending') {
      this.router.navigate([`/settings/users/${user.values.$key}`]);
    }
  }

  removeInvitation(invitation, e) {
    e.stopPropagation();
    this.auth.removeInvitation(invitation);
  }
}
