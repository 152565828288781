import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentData } from '@angular/fire/firestore';
import { units } from 'functions/src/shared/list';
import { Observable, of } from 'rxjs';
import { map, mergeAll } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LpService {

  private _itemsSubscription: any;
  public mandates$: Observable<any[]> = null;
  public mandates: any[] = [];
  public loaded = false;
  private _init = false;

  constructor(
    protected afs: AngularFirestore,
  ) { }

  init() {
    if (this._init) {
      return;
    }
    this._init = true;
    
    this.mandates$ = this.getRefusedMandates();

    this.loaded = false;

    if (this._itemsSubscription) {
      this._itemsSubscription.unsubscribe();
    }

    this._itemsSubscription = this.mandates$.subscribe(items => {
      this.mandates = items;
      this.loaded = true;
    });
  }

  getRefusedMandates() {
    const rentalRefused$ = this._colWithIds(`/db/modules/units`, (ref) => ref.where('syncWeb.rental.lp.errors', '==', 'Refused'));
    const saleRefused$ = this._colWithIds(`/db/modules/units`, (ref) => ref.where('syncWeb.sale.lp.errors', '==', 'Refused'));

    // Emit both observables as a single observable and use mergeAll to flatten them
    return of(rentalRefused$, saleRefused$).pipe(
      mergeAll(), // Flattens the observables into one stream
      map(docs => {
        // Combine and remove duplicates based on document id
        const uniqueDocs = Array.from(new Set(docs.map(doc => doc.id)))
          .map(id => docs.find(doc => doc.id === id));
        return uniqueDocs;
      })
    );
  }

  protected _colWithIds(path, queryFn?): Observable<DocumentData[]> {
    return this.afs.collection(path, queryFn).snapshotChanges()
      .pipe(
        map(actions => actions.map(a => ({
          id: a.payload.doc.id,
          ...units(a.payload.doc.data())
        })))
      );
  }
}