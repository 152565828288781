import { Component, OnInit } from '@angular/core';
import { SettingService } from '../../services/setting/setting.service';
import { AdminToolsService } from '../../services/admin-tools/admin-tools.service';
import { GlobalConstants } from 'functions/src/shared/global-constants';
import { Router } from '@angular/router';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent {

  public GLOBAL = new GlobalConstants();

  constructor(
    public setting: SettingService,
    public admin: AdminToolsService,
    protected router: Router,
  ) { }


  goBack() {
    this.router.navigate(['/']);
  }
}
