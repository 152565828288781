<mat-toolbar class="dashbg">
    <div>
        <a mat-icon-button routerLink='/'>
            <mat-icon>{{GLOBAL.ICONS.DASHBOARD}}</mat-icon>
        </a>
        <span>Dashboard</span>
    </div>
    <div>
        <profile></profile>
        <button mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
            <div mat-menu-item *ngIf="db.isOneOfRoles(['admin'])" (click)="goPage('/settings')">
                <mat-icon>{{GLOBAL.ICONS.SETTING}}</mat-icon>
                <span>Settings</span>
            </div>
            <div mat-menu-item>
                <mat-icon>{{GLOBAL.ICONS.VERSION}}</mat-icon>
                <span>{{setting.version}}</span>
            </div>
        </mat-menu>
    </div>
</mat-toolbar>
<div class="toolbartop"></div>


<div class="main-container" *ngIf="db.auth.profile?.values">
    <div class="mdc-layout-grid mainPage">
        <div class="mdc-layout-grid__inner">
            <div class="mdc-layout-grid__cell" *ngIf="db.isOneOfRoles(['admin', 'director', 'realtor'])">
                <mat-card (click)="goPage('projects')" class="backspace cursor title">
                    <mat-card-title class="projectsbg">{{myProjects.items.length}} Projects</mat-card-title>
                    <mat-list class="h">
                        <mat-list-item>
                            <mat-icon matListIcon class="hot">{{GLOBAL.ICONS.PROJECT}}</mat-icon>
                            <h3 matLine> {{myProjects.summary.hot}} </h3>
                        </mat-list-item>
                        <mat-list-item>
                            <mat-icon matListIcon class="warm">{{GLOBAL.ICONS.PROJECT}}</mat-icon>
                            <h3 matLine> {{myProjects.summary.warm}} </h3>
                        </mat-list-item>
                        <mat-list-item>
                            <mat-icon matListIcon class="cold">{{GLOBAL.ICONS.PROJECT}}</mat-icon>
                            <h3 matLine> {{myProjects.summary.cold}} </h3>
                        </mat-list-item>
                    </mat-list>

                    <mat-list class="h">
                        <mat-list-item *ngIf="myProjects.summary.new">
                            <mat-icon matListIcon class="hot">{{GLOBAL.ICONS.NEW}}</mat-icon>
                            <h3 matLine> {{myProjects.summary.new}} </h3>
                        </mat-list-item>
                        <mat-list-item *ngIf="myProjects.summary.discovery">
                            <mat-icon matListIcon class="warm">{{GLOBAL.ICONS.DISCOVERY}}</mat-icon>
                            <h3 matLine> {{myProjects.summary.discovery}} </h3>
                        </mat-list-item>
                        <mat-list-item *ngIf="myProjects.summary.pause">
                            <mat-icon matListIcon class="cold">{{GLOBAL.ICONS.PAUSE}}</mat-icon>
                            <h3 matLine> {{myProjects.summary.pause}} </h3>
                        </mat-list-item>
                        <mat-list-item *ngIf="myProjects.summary.offer">
                            <mat-icon matListIcon class="hot">{{GLOBAL.ICONS.OFFER}}</mat-icon>
                            <h3 matLine> {{myProjects.summary.offer}} </h3>
                        </mat-list-item>
                        <mat-list-item *ngIf="myProjects.summary.signLease">
                            <mat-icon matListIcon class="warm">{{GLOBAL.ICONS.SIGNLEASE}}</mat-icon>
                            <h3 matLine> {{myProjects.summary.signLease}} </h3>
                        </mat-list-item>
                        <mat-list-item *ngIf="myProjects.summary.signAgree">
                            <mat-icon matListIcon class="warm">{{GLOBAL.ICONS.SIGNAGREE}}</mat-icon>
                            <h3 matLine> {{myProjects.summary.signAgree}} </h3>
                        </mat-list-item>
                        <mat-list-item *ngIf="myProjects.summary.signDeed">
                            <mat-icon matListIcon class="warm">{{GLOBAL.ICONS.SIGNDEED}}</mat-icon>
                            <h3 matLine> {{myProjects.summary.signDeed}} </h3>
                        </mat-list-item>
                        <mat-list-item *ngIf="myProjects.summary.payment">
                            <mat-icon matListIcon class="hot">{{GLOBAL.ICONS.PAYMENT}}</mat-icon>
                            <h3 matLine> {{myProjects.summary.payment}} </h3>
                        </mat-list-item>
                    </mat-list>
                </mat-card>

            </div>

            <div class="mdc-layout-grid__cell" *ngIf="db.isOneOfRoles(['admin', 'director', 'realtor'])">
                <mat-card (click)="goPage('mandates')" class="backspace cursor title">
                    <mat-card-title class="mandatesbg">{{myMandates.summary.active}} Mandates</mat-card-title>
                    <mat-list class="h">
                        <mat-list-item>
                            <mat-icon matListIcon class="hot">{{GLOBAL.ICONS.MANDATE}}</mat-icon>
                            <h3 matLine> {{myMandates.summary.hot}} </h3>
                        </mat-list-item>
                        <mat-list-item>
                            <mat-icon matListIcon class="warm">{{GLOBAL.ICONS.MANDATE}}</mat-icon>
                            <h3 matLine> {{myMandates.summary.warm}} </h3>
                        </mat-list-item>
                        <mat-list-item>
                            <mat-icon matListIcon class="cold">{{GLOBAL.ICONS.MANDATE}}</mat-icon>
                            <h3 matLine> {{myMandates.summary.cold}} </h3>
                        </mat-list-item>
                    </mat-list>

                    <mat-list class="h">
                        <mat-list-item *ngIf="myMandates.summary.new">
                            <mat-icon matListIcon class="hot">{{GLOBAL.ICONS.NEW}}</mat-icon>
                            <h3 matLine> {{myMandates.summary.new}} </h3>
                        </mat-list-item>
                        <mat-list-item *ngIf="myMandates.summary.pending">
                            <mat-icon matListIcon class="warm">{{GLOBAL.ICONS.PENDING}}</mat-icon>
                            <h3 matLine> {{myMandates.summary.pending}} </h3>
                        </mat-list-item>
                        <mat-list-item *ngIf="myMandates.summary.pause">
                            <mat-icon matListIcon class="cold">{{GLOBAL.ICONS.PAUSE}}</mat-icon>
                            <h3 matLine> {{myMandates.summary.pause}} </h3>
                        </mat-list-item>
                        <mat-list-item *ngIf="myMandates.summary.offer">
                            <mat-icon matListIcon class="hot">{{GLOBAL.ICONS.OFFER}}</mat-icon>
                            <h3 matLine> {{myMandates.summary.offer}} </h3>
                        </mat-list-item>
                        <mat-list-item *ngIf="myMandates.summary.signLease">
                            <mat-icon matListIcon class="warm">{{GLOBAL.ICONS.SIGNLEASE}}</mat-icon>
                            <h3 matLine> {{myMandates.summary.signLease}} </h3>
                        </mat-list-item>
                        <mat-list-item *ngIf="myMandates.summary.signAgree">
                            <mat-icon matListIcon class="warm">{{GLOBAL.ICONS.SIGNAGREE}}</mat-icon>
                            <h3 matLine> {{myMandates.summary.signAgree}} </h3>
                        </mat-list-item>
                        <mat-list-item *ngIf="myMandates.summary.signDeed">
                            <mat-icon matListIcon class="warm">{{GLOBAL.ICONS.SIGNDEED}}</mat-icon>
                            <h3 matLine> {{myMandates.summary.signDeed}} </h3>
                        </mat-list-item>
                        <mat-list-item *ngIf="myMandates.summary.payment">
                            <mat-icon matListIcon class="hot">{{GLOBAL.ICONS.PAYMENT}}</mat-icon>
                            <h3 matLine> {{myMandates.summary.payment}} </h3>
                        </mat-list-item>
                    </mat-list>
                </mat-card>
            </div>


            <div class="mdc-layout-grid__cell" *ngIf="db.isOneOfRoles(['admin', 'director', 'realtor'])">
                <app-matchings-dash (click)="goPage('matchings')"></app-matchings-dash>
            </div>


            <div class="mdc-layout-grid__cell">
                <mat-card (click)="goPage('mytasks')" class="backspace cursor title">
                    <mat-card-title class="actionsbg">Tasks</mat-card-title>
                    <mat-list class="h">
                        <mat-list-item>
                            <mat-icon matListIcon class="active">{{GLOBAL.ICONS.ACTIONS}}</mat-icon>
                            <h3 matLine>Today: {{myActions.actionTime.today}} </h3>
                        </mat-list-item>
                        <mat-list-item>
                            <mat-icon matListIcon class="hot">{{GLOBAL.ICONS.ACTIONS}}</mat-icon>
                            <h3 matLine>Overdue: {{myActions.actionTime.overdue}} </h3>
                        </mat-list-item>
                    </mat-list>
                </mat-card>
            </div>

            <div class="mdc-layout-grid__cell" *ngIf="db.isOneOfRoles(['admin', 'marketing'])">
                <mat-card (click)="goPage('marketing')" class="backspace cursor title">
                    <mat-card-title class="marketingbg">Marketing</mat-card-title>
                    <mat-list>
                        <mat-list-item *ngIf="actionMarketingService.items?.length">
                            <mat-icon matListIcon class="active">{{this.GLOBAL.ICONS.MARKETINGCHECK}}</mat-icon>
                            <h3 matLine>Marketing Checks: {{actionMarketingService.items.length}} </h3>
                        </mat-list-item>
                        <mat-list-item *ngIf="lp.mandates?.length">
                            <mat-icon matListIcon class="hot">{{GLOBAL.ICONS.ALERT}}</mat-icon>
                            <h3 matLine>LP Refused: {{lp.mandates.length}} </h3>
                        </mat-list-item>
                    </mat-list>
                </mat-card>
            </div>

        </div>

    </div>
</div>