<mat-toolbar class="dashbg">
    <div>
        <a mat-icon-button (click)="clickBack()">
            <mat-icon>keyboard_arrow_left</mat-icon>
        </a>
        <span>Marketing Dashboard</span>
    </div>
    <div>
        <profile></profile>
    </div>
</mat-toolbar>
<div class="toolbartop"></div>


<div class="main-container" *ngIf="db.auth.profile?.values">
    <div class="mdc-layout-grid mainPage">
        <div class="mdc-layout-grid__inner">
            <div class="mdc-layout-grid__cell">
                <mat-card (click)="goPage('marketing/check')" class="backspace cursor title">
                    <mat-card-title class="actionsbg">Marketing Checks</mat-card-title>
                    <mat-list>
                        <mat-list-item>
                            <mat-icon matListIcon class="active">{{this.GLOBAL.ICONS.MARKETINGCHECK}}</mat-icon>
                            <h3 matLine>Pending Checks: {{actionMarketingService.items?.length}} </h3>
                        </mat-list-item>
                    </mat-list>
                </mat-card>
            </div>
            <div class="mdc-layout-grid__cell">
                <mat-card (click)="goPage('marketing/blogs')" class="backspace cursor title">
                    <mat-card-title class="actionsbg">Blogs</mat-card-title>
                    <mat-list>
                        <mat-list-item>
                            <mat-icon matListIcon class="active">{{this.GLOBAL.ICONS.BLOG}}</mat-icon>
                            <h3 matLine>Total Blogs: </h3>
                        </mat-list-item>
                    </mat-list>
                </mat-card>
            </div>
            <div class="mdc-layout-grid__cell">
                <mat-card (click)="goPage('marketing/portals/lp')" class="backspace cursor title">
                    <mat-card-title class="actionsbg">LP Refused Ads</mat-card-title>
                    <mat-list>
                        <mat-list-item>
                            <mat-icon matListIcon class="hot">{{GLOBAL.ICONS.ALERT}}</mat-icon>
                            <h3 matLine>Refused Ads: {{lp.mandates?.length}} </h3>
                        </mat-list-item>
                    </mat-list>
                </mat-card>
            </div>
        </div>
    </div>
</div>