import { Injectable } from '@angular/core';
import { MainService } from '../../common/services/main/main.service';
import { AuthService } from '../../common/services/auth/auth.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { Contact } from './contact.model';
import { Angular5Csv } from 'angular5-csv/dist/Angular5-csv';
import { formatDate } from 'functions/src/shared/tools';
import { Tel } from 'src/common/services/main/main.model';

@Injectable({
  providedIn: 'root'
})
export class ContactService extends MainService {

  public refMode = true;

  constructor(
    public auth: AuthService,
    public afs: AngularFirestore,
  ) {
    super(auth, afs, 'contact', 'contacts', Contact);
  }

  // Same as main expect that if number keep searching for telephone numbers...
  setSearchFilter(value: any) {
    if (value && value.length) {
      this._filters.search = value;
      this._filters.local.localSearch = value.toLowerCase();
      this._removeRefFilter();
      this._localFilter();
    } else {
      this._filters.search = null;
      if (this._filters.local.localSearch.length) {
        this._filters.local.localSearch = '';
        this._localFilter();
      }
      this._removeRefFilter();
    }

  }

  downloadFullListCSV() {
    this.downloadCSV(this.itemsFiltered);
  }

  downloadRealtorListCSV() {
    const contacts: any[] = [];
    for (const contact of this.itemsFiltered) {
      if (contact.realtors && contact.realtors[this.auth.uid]) {
        contacts.push(contact);
      }
    }
    this.downloadCSV(contacts);
  }

  downloadCSV(contacts: any[]) {

    let list = [];
    list.push({
      title: 'Title',
      firstname: 'Firstname',
      lastname: 'Lastname',
      tel: 'Tel',
      email: 'Email',
      language: 'Language',
      country: 'Country',
      citizen: 'Citizen',
      nationality: 'Nationality',
      birthday: 'Birthday',
      owner: 'Owner',
      renter: 'Renter',
      buyer: 'Buyer',
      renterActive: 'Active Renter',
      buyerActive: 'Active Buyer',
      gb: 'Grand Baie',
      pde: 'Pointe d\'Esny',
      mk: 'Moka',
      tam: 'Tamarin',
      cp: 'Curepipe',
    });
    for (const contact of contacts) {
      for (const emailKey in contact.emails) {
        const email = contact.emails[emailKey];
        if (email) {
          const lang = this._getLang(contact.lang, contact.nationalities, contact.country);
          list.push({
            title: this._getTitleLang(contact.title, lang),
            firstname: contact.firstname ? contact.firstname : '',
            lastname: contact.lastname ? contact.lastname : '',
            tel: this.extractNumbers(contact.tels),
            email: email.email,
            language: lang,
            country: contact.country ? contact.country : '',
            citizen: contact.nationalities.includes('MU'),
            nationality: this._getNationality(contact.nationalities),
            birthday: (contact.birthday && contact.birthday.seconds) ? formatDate(contact.birthday.toDate()) : '',
            owner: !!contact.owner,
            renter: !!contact.renter,
            buyer: !!contact.buyer,
            renterActive: !!contact.renterActive,
            buyerActive: !!contact.buyerActive,
            gb: !!(contact.agencies && contact.agencies.gb),
            pde: !!(contact.agencies && contact.agencies.pde),
            mk: !!(contact.agencies && contact.agencies.mk),
            tam: !!(contact.agencies && contact.agencies.tam),
            cp: !!(contact.agencies && contact.agencies.cp),

          });
        }
      }
    }

    new Angular5Csv(list, 'Contact Export');
  }

  extractNumbers(telObject: { [id: string]: Tel }): string {

    if (!telObject) {
      return ''; // Return an empty string if the parameter is null or undefined
    }

    const numbers: string[] = [];

    for (const telId in telObject) {
      if (telObject.hasOwnProperty(telId)) {
        numbers.push(telObject[telId].number);
      }
    }

    return numbers.join(', ');
  }

  private _getTitleLang(title: string, lang: string): string {
    if (title) {

      const titles: { [id: string]: { [id: string]: string } } = {
        en: {
          'MR': 'Mr',
          'MRS': 'Mrs',
          'MISS': 'Ms',
        },
        fr: {
          'MR': 'M.',
          'MRS': 'Mme',
          'MISS': 'Mlle',
        },
        de: {
          'MR': 'Herr',
          'MRS': 'Frau',
          'MISS': 'Miss',
        },
        ru: {
          'MR': 'Г-н',
          'MRS': 'Г-жа',
          'MISS': 'Мисс',
        },
      };

      if (titles[lang][title]) {
        return titles[lang][title];
      }
      return "";
    }
    return "";
  }

  private _getLang(lang: string, nationalities: string[], country: string): string {
    if (lang) {
      if (lang === 'mu' || lang === 'fr') {
        return "fr";
      }
      if (lang === 'de') {
        return "de";
      }
      if (lang === 'ru') {
        return "ru";
      }
      return "en";
    }

    if (nationalities && nationalities.length) {
      if (nationalities.includes('FR') ||
        nationalities.includes('RE') ||
        nationalities.includes('CH') ||
        nationalities.includes('BR')) {
        return 'fr';
      }
    }
    if (country) {
      if (country === 'FR' || country === 'RE' || country === 'CH' || country === 'BR') {
        return 'fr';
      }

      if (country === 'DE' || country === 'AT') {
        return 'de';
      }

      if (country === 'RU') {
        return 'ru';
      }
    }
    return 'en';
  }

  private _getNationality(nationalities: string[]): string {
    if (nationalities && nationalities.length) {
      if (nationalities.includes('MU')) {
        return 'MU';
      } else {
        return nationalities[0];
      }
    }
    return '';
  }
}