
import { Lang, MainItem, Field, Alert, AgencyFeeFields, AgencyFee } from '../../common/services/main/main.model';
import { SettingService } from '../../common/services/setting/setting.service';
import { checkStatusActive } from 'functions/src/shared/tools';

export class Mandate extends MainItem {

    values: {
        $key: string,
        ref: number,
        created: firebase.firestore.FieldValue,
        createdBy: string,
        updated: firebase.firestore.FieldValue,
        updatedBy: string,
        alerts: Alert[],

        synaps: any,

        actionUpdated: Date,

        type: string,
        status: string,
        origin: string,
        originNote: string,
        ad: boolean,
        temperature: string,
        realtor: string,
        agency: string,

        dateStart: Date,
        dateAvailable: Date,
        duration: number, // month
        exclusive: boolean,
        note: string,
        desc: Lang,

        // Accounting
        price: number,
        // priceFurniture: number,
        priceNeg: number,
        priceVisible: boolean,
        noAgencyFee: boolean,
        cy: string,
        priceCies: { [id: string]: number },
        priceNegCies: { [id: string]: number },

        agencyFee: AgencyFee,
        referral: string,
        referralAgency: string,
        referralCom: number,

        portals: { [id: string]: string | boolean } | null,

        dateEnd: Date,
        failedReason: string,
        failedNote: string,

        anyoneContact: boolean,
        keyOffice: boolean,
        organiseVisitNote: string,

        // unit: Unit, // Dynamic
        // docs: Upload[],

        // daysStart: number, // Calculated
        // daysAvailable: number, // Calculated
        // match: any, // Synap Matching
        // matchingStats: { new: number, yes: number, no: number }, // Calcul Stats

        marketingCheck: boolean
        matching: boolean,
        matchingResults: any,
    }

    constructor(
        values: any = null,
        public setting?: SettingService,
    ) {
        super(
            'mandates',
            {
                actionUpdated: new Field({
                    input: 'date',
                }),
                type: new Field({
                    label: 'Type', input: 'select', inputOptions: { param: 'mandateTypes' },
                    validations: { required: true }
                }),
                status: new Field({ label: 'Status' }),

                origin: new Field({
                    label: 'Origin', input: 'select', inputOptions: { param: 'mandateOrigins' },
                    validations: { required: true },
                }),
                originNote: new Field({
                    label: 'Origin Note', input: 'textarea',
                    validations: { required: true },
                }),
                temperature: new Field({
                    label: 'Temperature', input: 'select', inputOptions: { param: 'temperatures' },
                    validations: { required: true }
                }),
                realtor: new Field({
                    label: 'Agent', input: 'select', inputOptions: { param: 'realtors' },
                    validations: { required: true },
                    show: (data, subData, setting) => {
                        return ['admin', 'director', 'assistant', 'marketing'].includes(setting.auth.profile.values.role)
                    }

                }),
                agency: new Field({
                    label: 'Agency', input: 'select', inputOptions: { param: 'tmp' },
                    validations: { required: true },
                    show: (data, subData, setting) => {
                        if (setting.show === false) {
                            setting.show = true;
                            return false;
                        }
                        return data.realtor && setting && setting.users && setting.users[data.realtor] && setting.users[data.realtor].agencies && setting.users[data.realtor].agencies.length > 1
                    }
                    // autoFn: {
                    //     toFB: (values, setting) => { return this._getAgency(setting, data.realtor) }
                    // }
                }),
                referral: new Field({
                    label: 'Referral', input: 'select', inputOptions: { param: 'users' },
                }),
                referralAgency: new Field({
                    label: 'Referral Agency', input: 'auto',
                    autoFn: {
                        toFB: (values, setting) => { return setting.getAgency(values.referral); }
                    },
                }),
                referralCom: new Field({
                    input: 'number', label: 'Referral (%)',
                    default: 25,
                    show: (data: any) => data && data.referral,
                }),

                dateStart: new Field({
                    label: 'Mandate Starting Date', input: 'date', default: new Date(),
                    validations: { required: true }
                }),
                dateAvailable: new Field({
                    label: 'Unit Available', input: 'date', default: new Date(),
                    validations: { required: true }
                }),
                duration: new Field({
                    input: 'number', label: 'Duration (Optional - Months)',
                }),
                exclusive: new Field({
                    label: 'Exclusive',
                    input: 'boolean',
                    default: false
                }),
                note: new Field({ label: 'Note', }),
                desc: new Field({ multiLang: true, input: 'textarea', label: 'Portal Description' }),

                // Price
                price: new Field({
                    input: 'number', label: 'Total Price',
                }),
                // priceFurniture: new Field({
                //     input: 'number', label: 'Incl. Furniture Price',
                // }),
                priceNeg: new Field({
                    input: 'number', label: 'Negociable Price',
                }),
                cy: new Field({
                    label: 'Currency', input: 'select', inputOptions: { param: 'cies' }, default: 'mur',
                }),
                agencyFee: new Field({
                    label: 'Mandate\'s Agency Fee',
                    input: 'objectSub',
                    sub: new AgencyFeeFields(),
                }),
                priceVisible: new Field({
                    label: 'Show Price',
                    input: 'boolean',
                    default: true,
                }),
                noAgencyFee: new Field({
                    label: 'No Agency Fee for Buyer',
                    input: 'boolean',
                    default: false,
                    show: (data: any) => data && data.type === 'sale',
                }),
                ad: new Field({
                    label: 'Advertise',
                    input: 'boolean',
                    default: false,
                }),
                portals: new Field({
                    label: 'Portals',
                    input: 'objectSub',
                    show: (data: any) => data && data.ad,
                    sub: {
                        web: new Field({ input: 'boolean', label: 'Website', default: true, }),
                        lp: new Field({ input: 'boolean', label: 'L\'Express Property', default: true, }),
                        pc: new Field({ input: 'boolean', label: 'PropCloud', default: true, }),
                        je: new Field({ input: 'boolean', label: 'JamesEdition', default: true, }),
                        pm: new Field({ input: 'boolean', label: 'PropertyMap', default: true, }),
                        ta: new Field({ input: 'boolean', label: 'The Agency', default: false, }),
                        apimo: new Field({ input: 'boolean', label: 'Polo Properties', default: false, }),
                        mer: new Field({ input: 'boolean', label: 'Mer & Demeures', default: false, }),
                        ps: new Field({ input: 'boolean', label: 'Properstar', default: false, }),
                    }
                }),

                keyOffice: new Field({ input: 'boolean', label: 'Key at the Office', default: false }),
                dateEnd: new Field({
                    label: 'Mandate Ending Date', input: 'date', default: new Date(),
                }),
                failedReason: new Field(),
                failedNote: new Field(),
                anyoneContact: new Field({ input: 'boolean', label: 'Usual Process for the visit', default: true }),
                organiseVisitNote: new Field({
                    label: 'Organise Visit', input: 'textarea',
                }),
                priceCies: new Field({
                    input: 'auto',
                    autoFn: {
                        toFB: (values, setting) => { return this._toCies(values.price, values.cy, setting) }
                    }
                }),
                priceNegCies: new Field({
                    input: 'auto',
                    autoFn: {
                        toFB: (values, setting) => { return this._toCies(values.priceNeg, values.cy, setting) }
                    }
                }),
                matching: new Field({ input: 'boolean', default: false }),
                matchingResults: new Field({ input: 'object' }),
                marketingCheck: new Field({ input: 'boolean', default: false }),
            },
            // Forms
            {
                main: {
                    title: null,
                    fields: ['exclusive', 'dateAvailable', 'agencyFee', 'noAgencyFee', 'referral', 'referralCom']
                },
                contact: {
                    title: null,
                    fields: ['anyoneContact', 'keyOffice', 'organiseVisitNote']
                },
                newRealtor: {
                    title: null,
                    fields: ['type', 'origin', 'originNote', 'realtor', 'agency', 'referral', 'referralCom']
                },
                newVEFA: {
                    title: null,
                    fields: ['price', 'cy']
                },
                new: {
                    title: null,
                    fields: ['type', 'origin',]
                },
                accounting: {
                    title: null,
                    fields: ['agencyFee']
                },
                portals: {
                    title: null,
                    fields: ['ad', 'priceVisible', 'portals']
                },
            },
            // Tables
            {
                main: {
                    title: 'Mandate List',
                    columns: [
                        { field: 'icon', type: 'icon', label: 'Priority' },
                        { field: 'ref', type: 'string', label: 'Ref', options: { prefix: 'NES' } },
                        { field: 'city', type: 'string', label: 'City' },
                        { field: 'residence', type: 'string', label: 'Residence' },
                        { field: 'unitTypeIcon', type: 'icon', label: 'Unit Type' },
                        { field: 'bedrooms', type: 'string', label: 'Beds' },
                        { field: 'owners', type: 'string', label: 'Owners', options: { multi: true } },
                        { field: 'priceDisplay', type: 'string', label: 'Price' },
                        { field: 'followUp', type: 'days', label: 'Follow Up' },
                        { field: 'matchingsDisplay', type: 'string', label: 'Matchings' },
                    ],
                },
                realtor: {
                    title: 'Mandate List',
                    columns: [
                        { field: 'icon', type: 'icon', label: 'Priority' },
                        { field: 'ref', type: 'string', label: 'Ref', options: { prefix: 'NES' } },
                        { field: 'city', type: 'string', label: 'City' },
                        { field: 'residence', type: 'string', label: 'Residence' },
                        { field: 'unitTypeIcon', type: 'icon', label: 'Unit Type' },
                        { field: 'bedrooms', type: 'string', label: 'Beds' },
                        { field: 'owners', type: 'string', label: 'Owners', options: { multi: true } },
                        { field: 'priceDisplay', type: 'string', label: 'Price' },
                        { field: 'followUp', type: 'days', label: 'Follow Up' },
                        { field: 'matchingsDisplay', type: 'string', label: 'Matchings' },
                        { field: 'realtor', type: 'user', label: 'Agent' },
                    ],
                },
                referral: {
                    title: 'Mandate List',
                    columns: [
                        { field: 'icon', type: 'icon', label: 'Priority' },
                        { field: 'ref', type: 'string', label: 'Ref', options: { prefix: 'NES' } },
                        { field: 'city', type: 'string', label: 'City' },
                        { field: 'residence', type: 'string', label: 'Residence' },
                        { field: 'unitTypeIcon', type: 'icon', label: 'Unit Type' },
                        { field: 'bedrooms', type: 'string', label: 'Beds' },
                        { field: 'owners', type: 'string', label: 'Owners', options: { multi: true } },
                        { field: 'priceDisplay', type: 'string', label: 'Price' },
                        { field: 'followUp', type: 'days', label: 'Follow Up' },
                        { field: 'referralCom', type: 'number', label: 'Referral Com', options: { prefix: '%' } },
                        { field: 'realtor', type: 'user', label: 'Agent' },
                    ],
                },
                referralRealtor: {
                    title: 'Mandate List',
                    columns: [
                        { field: 'icon', type: 'icon', label: 'Priority' },
                        { field: 'ref', type: 'string', label: 'Ref', options: { prefix: 'NES' } },
                        { field: 'city', type: 'string', label: 'City' },
                        { field: 'residence', type: 'string', label: 'Residence' },
                        { field: 'unitTypeIcon', type: 'icon', label: 'Unit Type' },
                        { field: 'bedrooms', type: 'string', label: 'Beds' },
                        { field: 'owners', type: 'string', label: 'Owners', options: { multi: true } },
                        { field: 'priceDisplay', type: 'string', label: 'Price' },
                        { field: 'followUp', type: 'days', label: 'Follow Up' },
                        { field: 'referral', type: 'user', label: 'Referral Agent' },
                        { field: 'referralCom', type: 'number', label: 'Referral Com', options: { suffix: '%' } },
                        { field: 'realtor', type: 'user', label: 'Agent' },
                    ],
                },
            },
            // Config File
            {
                path: 'mandates/', filename: (data) => {
                    let filename = 'mandate-';
                    filename += 'NES' + data.ref;
                    return filename;
                }
            },
            setting,
        );
        if (values) {
            this.fromFB(values);
        }

    }

    private _toCies(price: number, fromCy: string, setting: SettingService): any {

        const obj: any = {};

        if (price && fromCy) {
            for (const toCy of setting.getParamsSelect('cies')) {
                if (setting.exRates[fromCy] && setting.exRates[fromCy][toCy.id]) {
                    obj[toCy.id] = Math.floor(this.setting.exRates[fromCy][toCy.id] * price);
                }
            }
        }

        return obj;
    }

    _fnGetAutoData() {
        checkStatusActive(this.values);
    }
}