import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HttpClientModule } from '@angular/common/http';

import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';

// EXTERNAL MODULES

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatTableModule } from '@angular/material/table';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { DateAdapter, MAT_DATE_FORMATS, MatNativeDateModule } from '@angular/material/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSortModule } from '@angular/material/sort';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { TableVirtualScrollModule } from 'ng-table-virtual-scroll';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatBadgeModule } from '@angular/material/badge';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { AgmCoreModule } from '@agm/core';
import { AgmJsMarkerClustererModule } from '@agm/js-marker-clusterer';

import { SortablejsModule } from 'ngx-sortablejs';

// COMMON
import { UsersComponent } from '../common/pages/users/users.component';
import { UserComponent } from '../common/pages/user/user.component';
import { LoginComponent } from '../common/pages/login/login.component';
import { SettingsComponent } from '../common/pages/settings/settings.component';
import { SettingsListComponent, DialogParam } from '../common/pages/settings-list/settings-list.component';
import { ToarrayPipe } from '../common/pipes/toarray.pipe';
import { MainsComponent } from '../common/pages/mains/mains.component';
import { MainComponent } from '../common/pages/main/main.component';
import { FormBuilderComponent } from '../common/components/form-builder/form-builder.component';
import { FieldBuilderComponent } from '../common/components/field-builder/field-builder.component';
import { ParamPipe } from '../common/pipes/param.pipe';
import { ParamsPipe } from '../common/pipes/params.pipe';
import { UploadImageComponent } from '../common/components/upload-image/upload-image.component';
import { FileDropDirective } from '../common/directives/file-drop.directive';


// SPECIFIC
import { DashboardComponent } from '../pages/dashboard/dashboard.component';
import { ProfileComponent } from '../common/components/profile/profile.component';
import { UnitsComponent } from '../pages/units/units.component';
import { DialogAddMandateComponent,  UnitComponent } from '../pages/unit/unit.component';
import { TableComponent } from '../common/components/table/table.component';
import { GmapComponent } from '../common/components/gmap/gmap.component';
import { FieldDisplayComponent } from '../common/components/field-display/field-display.component';
import { SlideshowComponent } from '../common/components/slideshow/slideshow.component';
import { ThumbPipe } from '../common/pipes/thumb.pipe';
import { GpsComponent } from '../common/components/gps/gps.component';
import { AddressPipe } from '../common/pipes/address.pipe';
import { ResidencesComponent } from '../pages/residences/residences.component';
import { DialogAddUnitComponent, ResidenceComponent } from '../pages/residence/residence.component';
import { ContactsComponent } from '../pages/contacts/contacts.component';
import { ContactComponent, DialogAddProjectComponent } from '../pages/contact/contact.component';
import { MandatesComponent } from '../pages/mandates/mandates.component';
import { DialogFailedMandateComponent, MandateComponent } from '../pages/mandate/mandate.component';
import { ResidenceTabComponent } from '../pages/residence-tab/residence-tab.component';
import { ContactTabComponent } from '../pages/contact-tab/contact-tab.component';
import { UploadImagesComponent } from '../common/components/upload-images/upload-images.component';
import { CompaniesComponent } from '../pages/companies/companies.component';
import { CompanyComponent } from '../pages/company/company.component';
import { ProjectsComponent } from '../pages/projects/projects.component';
import { DialogFailedProjectComponent, ProjectComponent } from '../pages/project/project.component';
import { CompanyTabComponent } from '../pages/company-tab/company-tab.component';
import { UnitCardComponent } from '../components/cards/unit-card/unit-card.component';
import { IconPipe } from '../common/pipes/icon.pipe';
import { MandateListCardComponent } from '../components/mandate-list-card/mandate-list-card.component';
import { ResidenceCardComponent } from '../components/cards/residence-card/residence-card.component';
import { ToarraydynPipe } from '../common/pipes/toarraydyn.pipe';
import { MandateIconColorPipe } from '../pipes/mandate-icon-color.pipe';
import { SectorsSelectComponent } from '../components/sectors-select/sectors-select.component';
import { MandateHcardComponent } from '../components/cards/mandate-hcard/mandate-hcard.component';
import { VisitMatchingsTableComponent } from '../components/visit-matchings-table/visit-matchings-table.component';
import { RealtorPipe } from '../pipes/realtor.pipe';
import { ActionEditComponent } from '../components/actions-list/action-edit/action-edit.component';
import { SpeedDialFabComponent } from '../common/components/speed-dial-fab/speed-dial-fab.component';
import { VisitEditComponent } from '../components/visit-edit/visit-edit.component';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { DatetimeDialogComponent } from '../common/components/datetime-dialog/datetime-dialog.component';
import { NewRequestDisplayComponent } from '../pages/project/actions/new-request/new-request.component';
import { EditMatchingDialogComponent, MandateMatchingsComponent } from '../pages/project/mandate-matchings.component';
import { NewMatchingsComponent } from '../pages/project/new-matchings.component';
import { NotNewPipe } from '../pipes/not-new.pipe';
import { DialogConfirmationComponent } from '../common/components/dialog-confirmation/dialog-confirmation.component';
import { SwapAgentComponent } from '../components/actions-dialog/swap-agent/swap-agent.component';
import { VisitReportComponent } from '../components/visit-report/visit-report.component';
import { NewProjectMatchingsComponent } from '../pages/mandate/new-project-matchings.component';
import { ProjectMatchingsComponent } from '../pages/mandate/project-matchings.component';
import { StarRatingComponent } from '../components/star-rating/star-rating.component';
import { ProjectCardComponent } from '../components/cards/project-card/project-card.component';
// import { NewRequestsComponent } from '../pages/new-requests/new-requests.component';
import { NewRequestComponent } from '../pages/new-request/new-request.component';
import { ContactCardComponent } from '../components/cards/contact-card/contact-card.component';
import { MandateCardComponent } from '../components/cards/mandate-card/mandate-card.component';
import { NewRequestCardComponent } from '../components/cards/new-request-card/new-request-card.component';
import { LogsComponent } from '../common/pages/logs/logs.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RealtorNamePipe } from '../pipes/realtor-name.pipe';
import { TemperatureComponent } from '../components/actions-dialog/temperature/temperature.component';
import { MatchingsComponent } from 'src/pages/matchings/matchings.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { StatsComponent } from '../pages/stats/stats.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { AssignedLeadsComponent } from '../pages/assigned-leads/assigned-leads.component';
import { ActionsComponent } from '../pages/actions/actions.component';
import { CardEditComponent } from '../common/components/card-edit/card-edit.component';
import { MandateEditComponent } from '../components/mandate-edit/mandate-edit.component';
import { AdvertiseEditComponent } from '../components/advertise-edit/advertise-edit.component';
import { OrganiseVisitEditComponent } from '../pages/project/actions/organise-visit-edit/organise-visit-edit.component';
import { AdvertiseListActionComponent } from '../pages/mandate/actions/advertise/advertise.component';
import { MandateFailedComponent } from '../pages/mandate/actions/failed/failed.component';
import { R2Component } from '../pages/mandate/actions/r2/r2.component';
import { MarketingCheckComponent } from '../pages/mandate/actions/marketing-check/marketing-check.component';
import { HandoverComponent } from '../components/actions-list/handover/handover.component';
import { RealtorComponent } from 'src/components/actions-list/realtor/realtor.component';
import { ProjectFailedComponent } from 'src/pages/project/actions/failed/failed.component';
import { ActionTemperatureComponent } from 'src/components/actions-list/temperature/temperature.component';
import { MandateNewRequestComponent } from 'src/pages/mandate/actions/new-request/new-request.component';
import { NewMandateComponent } from '../pages/mandate/actions/new-mandate/new-mandate.component';
import { PriceComponent } from '../pages/mandate/actions/price/price.component';
import { R1Component } from '../pages/mandate/actions/r1/r1.component';
import { DiscoveryComponent } from '../pages/project/actions/discovery/discovery.component';
import { ActionSelectComponent } from '../components/actions-list/action-select/action-select.component';
import { PaymentComponent } from '../components/actions-list/payment/payment.component';
import { ActionComponent } from '../pages/action/action.component';
import { ActionSignComponent } from '../pages/action/components/action-sign/action-sign.component';
import { ActionMainComponent } from '../pages/action/components/action-main/action-main.component';
import { SignComponent } from 'src/components/actions-list/sign/sign.component';
import { ActionDatePipe } from '../pipes/action-date.pipe';
import { ActionSignMandateComponent } from '../pages/action/components/action-sign-mandate/action-sign-mandate.component';
import { MandateSignComponent } from 'src/components/actions-list/mandate-sign/mandate-sign.component';
import { MytasksComponent } from '../pages/mytasks/mytasks.component';
import { AgencyFeeMandateEditComponent, AgencyFeeProjectEditComponent } from '../components/agency-fee-edit/agency-fee-edit.component';
import { ProjectEditComponent } from '../components/project-edit/project-edit.component';
import { AgenciesComponent } from '../common/pages/agencies/agencies.component';
import { AgencyComponent } from '../common/pages/agency/agency.component';
import { MatchingsDashComponent } from '../components/matchings-dash/matchings-dash.component';
import { LpComponent } from '../pages/marketing/portals/lp/lp.component';
import { MarketingDashboardComponent } from 'src/pages/marketing/marketing-dashboard/marketing-dashboard.component';
import { MarketingComponent } from 'src/pages/marketing/new-mandate-check/marketing.component';
import { BlogsComponent } from 'src/pages/marketing/blogs/blogs.component';
import { BlogComponent } from 'src/pages/marketing/blog/blog.component';
import { MatchingReportComponent } from '../components/matching-report/matching-report.component';
import { AllLogsComponent } from '../common/pages/all-logs/all-logs.component';



const firebaseConfig = {
  // apiKey: "AIzaSyA_p9iGCreSnoS7dcY3pgVJPEQ4xTu_064",
  // authDomain: "nestenn-app-2.firebaseapp.com",
  // databaseURL: "https://nestenn-app-2.firebaseio.com",
  // projectId: "nestenn-app-2",
  // storageBucket: "nestenn-app-2.appspot.com",
  // messagingSenderId: "911959057889",
  // appId: "1:911959057889:web:ba156f9f66543f5cd75ea0", 
  // googleMapKey: "AIzaSyA_p9iGCreSnoS7dcY3pgVJPEQ4xTu_064"

  apiKey: "AIzaSyA29JmFXbXqmGQwAFtuFmIohF1a81NsunI",
  authDomain: "nestenn-app.firebaseapp.com",
  databaseURL: "https://nestenn-app.firebaseio.com",
  projectId: "nestenn-app",
  storageBucket: "nestenn-app.appspot.com",
  messagingSenderId: "151194352805",
  appId: "1:151194352805:web:b98443026d3e5e0468e14c",
  googleMapKey : "AIzaSyA-gzW94ffcN7U26pxvBsFOY83eksETJ5o"
};


@NgModule({
  declarations: [
    AppComponent,
    SettingsComponent,
    SettingsListComponent,
    DialogParam,
    ToarrayPipe,
    MainsComponent,
    MainComponent,
    FormBuilderComponent,
    FieldBuilderComponent,
    ParamPipe,
    ParamsPipe,
    UploadImageComponent,
    FileDropDirective,
    LoginComponent,
    UsersComponent,
    UserComponent,
    DashboardComponent,
    ProfileComponent,
    UnitsComponent,
    UnitComponent,
    DialogAddMandateComponent,
    TableComponent,
    GmapComponent,
    FieldDisplayComponent,
    SlideshowComponent,
    ThumbPipe,
    GpsComponent,
    AddressPipe,
    ResidencesComponent,
    ResidenceComponent,
    ContactsComponent,
    ContactComponent,
    MandatesComponent,
    MandateComponent,
    NewProjectMatchingsComponent,
    ProjectMatchingsComponent,
    MandateMatchingsComponent,
    MandateSignComponent,
    DialogFailedMandateComponent,
    ResidenceTabComponent,
    DialogAddUnitComponent,
    ContactTabComponent,
    UploadImagesComponent,
    CompaniesComponent,
    CompanyComponent,
    ProjectsComponent,
    ProjectComponent,
    DialogAddProjectComponent,
    MatchingsComponent,
    DialogFailedProjectComponent,
    EditMatchingDialogComponent,
    NewMatchingsComponent,
    CompanyTabComponent,
    UnitCardComponent,
    IconPipe,
    MandateListCardComponent,
    ResidenceCardComponent,
    ToarraydynPipe,
    MandateIconColorPipe,
    SectorsSelectComponent,
    MandateHcardComponent,
    VisitMatchingsTableComponent,
    RealtorPipe,
    ActionEditComponent,
    SpeedDialFabComponent,
    VisitEditComponent,
    DatetimeDialogComponent,
    NewRequestDisplayComponent,
    NotNewPipe,
    DialogConfirmationComponent,
    SwapAgentComponent,
    VisitReportComponent,
    StarRatingComponent,
    ProjectCardComponent,
    NewRequestComponent,
    ContactCardComponent,
    MandateCardComponent,
    NewRequestCardComponent,
    LogsComponent,
    MarketingComponent,
    RealtorNamePipe,
    TemperatureComponent,
    ActionTemperatureComponent,
    BlogsComponent,
    BlogComponent,
    StatsComponent,
    AssignedLeadsComponent,
    ActionsComponent,
    CardEditComponent,
    MandateEditComponent,
    AdvertiseEditComponent,
    OrganiseVisitEditComponent,
    AdvertiseListActionComponent,
    MandateFailedComponent,
    ProjectFailedComponent,
    R2Component,
    MarketingCheckComponent,
    HandoverComponent,
    RealtorComponent,
    MandateNewRequestComponent,
    NewMandateComponent,
    PriceComponent,
    R1Component,
    DiscoveryComponent,
    ActionSelectComponent,
    PaymentComponent,
    SignComponent,
    ActionComponent,
    ActionSignComponent,
    ActionMainComponent,
    ActionDatePipe,
    ActionSignMandateComponent,
    MytasksComponent,
    AgencyFeeProjectEditComponent,
    AgencyFeeMandateEditComponent,
    ProjectEditComponent,
    AgenciesComponent,
    AgencyComponent,
    MatchingsDashComponent,
    LpComponent,
    MarketingDashboardComponent,
    MatchingReportComponent,
    AllLogsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFirestoreModule, // firestore
    AngularFireAuthModule, // auth
    HttpClientModule,
    ReactiveFormsModule,
    AgmCoreModule.forRoot({
      apiKey: firebaseConfig.googleMapKey,
      libraries: ["places", "geometry"]
    }),
    AgmJsMarkerClustererModule,
    FormsModule,
    BrowserAnimationsModule,
    MatTabsModule, MatSidenavModule, MatToolbarModule, MatIconModule, MatButtonModule, MatListModule,
    MatFormFieldModule, MatSelectModule, MatInputModule, MatButtonModule, MatCheckboxModule, MatBottomSheetModule,
    MatListModule, MatSlideToggleModule, MatCardModule, MatMenuModule, MatGridListModule, MatTableModule,
    MatDialogModule, MatDatepickerModule, MatNativeDateModule, MatProgressBarModule, MatProgressSpinnerModule,
    MatSortModule, MatAutocompleteModule, MatExpansionModule, MatBadgeModule, MatTooltipModule,
    MatButtonToggleModule,
    ScrollingModule,
    SortablejsModule.forRoot({ animation: 150 }),
    TableVirtualScrollModule,
    CKEditorModule,
    NgxChartsModule,
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
