import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as firebase from 'firebase/app';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class LogsService {

  private _itemsSubcription: any;
  public logs$: Observable<any[]> = null
  logs: any[] = [];
  public loaded = false;

  constructor(
    protected afs: AngularFirestore,
    protected auth: AuthService,
  ) { }

  init(module: string, $key: string, startDate?: Date, endDate?: Date, logType?: 'view' | 'edit', uid?: string) {

    this.logs$ = this._colWithIds(`/logs`, (ref) => this._queryFn(ref, module, $key, startDate, endDate, logType, uid));

    this.loaded = false;

    if (this._itemsSubcription) {
      this._itemsSubcription.unsubscribe();
    }

    this._itemsSubcription = this.logs$.subscribe(items => {
      this.logs = items;
      this.loaded = true;
    });
  }

  protected _queryFn(ref, module: string, $key?: string, startDate?: Date, endDate?: Date, logType?: 'view' | 'edit', uid?: string): any {

    let query = ref.where('item.module', '==', module);
    if ($key) {
      query = query.where('item.key', '==', $key);
    }

    if (startDate) {
      query = query.where('created', '>=', startDate);
    }

    if (endDate) {
      query = query.where('created', '<=', endDate);
    }

    if (logType) {
      query = query.where('type', '==', logType);
    }

    if (uid) {
      query = query.where('uid', '==', uid);
    }

    query = query.orderBy('created', 'desc');

    return query;
  }


  view(module: string, $key: string) {
    firebase.firestore().doc(`logs/${firebase.firestore().collection('/logs').doc().id}`).set(
      {
        created: firebase.firestore.FieldValue.serverTimestamp(),
        item: {
          key: $key,
          module: module,
        },
        uid: this.auth.uid,
        type: 'view',
      });

  }


  protected _colWithIds(path, queryFn?): Observable<any[]> {
    return this.afs.collection(path, queryFn).snapshotChanges()
      .pipe(
        map(actions => {
          return actions.map(a => {
            return a.payload.doc.data();
            // return new this.itemClass(a.payload.doc.data());
          });
        })
      );
  }
}
